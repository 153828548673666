import React, { useState } from 'react';
import clsx from 'clsx';
import { useFormik } from 'formik';
import Container from '../../container/container';
import { Step, Stepper, useStepper } from '../../stepper/stepper';
import { SuccessStep } from '../../stepper/success-step';
import { ErrorStep } from '../../stepper/error-step';
import { SecondaryButton } from '../../button/secondary-button';
import { RadioButtonGroup } from '../../forms/radio-button';
import FORM_TYPES from '../../../../api/_form-types-handlers/FORM_TYPES';
import IconWebdesign from '../../../images/icons/project-inquiry/icon-form-webdisign.svg';
import IconOnlineShop from '../../../images/icons/project-inquiry/icon-form-online-shop.svg';
import IconOnlineMarketing from '../../../images/icons/project-inquiry/icon-form-online-marketing.svg';
import IconDontKnow from '../../../images/icons/project-inquiry/icon-form-dont-know.svg';

import IconEinzelunternehmen from '../../../images/icons/project-inquiry/icon-form-einzelunternehmen.svg';
import Icon2_10_Mitarb from '../../../images/icons/project-inquiry/icon-form-2-10-mitarbeiter.svg';
import Icon10_50_Mitarb from '../../../images/icons/project-inquiry/icon-form-10-50-mitarbeiter.svg';
import Icon_50_Mitarb from '../../../images/icons/project-inquiry/icon-mehr-50-mitarbeiter.svg';

import IconSofort from '../../../images/icons/project-inquiry/icon-form-schnellst-moglich.svg';
import Icon4_6Wochen from '../../../images/icons/project-inquiry/icon-form-4-6-wochen.svg';
import Icon8_10Wochen from '../../../images/icons/project-inquiry/icon-form-8-10-wochen.svg';
import IconDontKnowCal from '../../../images/icons/project-inquiry/icon-form-dont-know-calendar.svg';
import IconSSL from '../../../images/icons/project-inquiry/ssl-dark-icon.svg';
import { TextField } from '../../forms/text-field';
import { Checkbox } from '../../forms/checkbox';
import { SubmitButton } from '../../button/submit';
import styles from './project-inquiry-section.module.scss';
import { useCallback } from 'react';

const interests = [
  {
    label: 'Webseite / Webapp',
    value: 'webdesign',
    icon: IconWebdesign,
  },
  {
    label: 'Online Shop',
    value: 'online-shop',
    icon: IconOnlineShop,
  },
  {
    label: 'Online Marketing',
    value: 'online-marketing',
    icon: IconOnlineMarketing,
  },
  {
    label: 'Weiß ich nicht',
    value: 'nicht-sicher',
    icon: IconDontKnow,
  },
];

const schedulers = [
  {
    label: 'Schnellst Möglich',
    value: 'sofort',
    icon: IconSofort,
  },
  {
    label: 'In 4 bis 6 Wochen',
    value: '4-6-wochen',
    icon: Icon4_6Wochen,
  },
  {
    label: 'In 8 bis 10 Wochen',
    value: '8-10-wochen',
    icon: Icon8_10Wochen,
  },
  {
    label: 'Weiß ich nicht',
    value: 'nicht-sicher',
    icon: IconDontKnowCal,
  },
];

const companySizes = [
  {
    label: 'Einzelunternehmen',
    value: 'einzelunternehmen',
    icon: IconEinzelunternehmen,
  },
  {
    label: '2-10 Mitarbeiter',
    value: '2-10-mitarbeiter',
    icon: Icon2_10_Mitarb,
  },
  {
    label: '10-50 Mitarbeiter',
    value: '2-10-mitarbeiter',
    icon: Icon10_50_Mitarb,
  },
  {
    label: '50+ Mitarbeiter',
    value: '50+mitarbeiter',
    icon: Icon_50_Mitarb,
  },
];

const validate = (values) => {
  const errors = {};
  const maxNameLength = 80;

  if (!values.name) {
    errors.name = 'Ups, dieses Angabe brauchen wir auf jeden Fall.';
  } else if (values.name.length > maxNameLength) {
    errors.name = `Ups, hängt bei Ihnen eine der Tasten? Sie haben zu langen Text hier angegeben! Bitte maximal ${maxNameLength} Zeichen angeben.`;
  }

  if (!values.email) {
    errors.email = 'Ups, dieses Angabe brauchen wir auf jeden Fall.';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Geben Sie bitte eine gültige E-Mail-Adresse an.';
  }

  if (!values.phone) {
    errors.phone = 'Ups, dieses Angabe brauchen wir auf jeden Fall.';
  } else if (values.phone.length > 20) {
    errors.phone = 'Geben Sie bitte eine gültige Telefonnummer an.';
  }

  if (!values.agreement) {
    errors.agreement = 'Akzeptieren Sie bitte die Datenschutzbedingungen';
  }

  return errors;
};

export function ProjectInquirySection({ headerId = 'inquiry-header' }) {
  const numSteps = 4;
  const { activeStep, next, prev, finish, reset: resetStepper, showErrorStep } = useStepper({
    initialActiveStep: 0,
    numSteps: numSteps,
  });
  const [serverError, setServerError] = useState(false);

  const { handleChange, errors, touched, handleBlur, isSubmitting, handleSubmit, values, resetForm } = useFormik({
    initialValues: {
      interest: 'webdesign',
      companySize: '',
      scheduler: '',
      email: '',
      phone: '',
      name: '',
      agreement: false,
      formType: FORM_TYPES.INQUIRY_STEPPER,
    },
    validate: validate,
    onSubmit: async (data, { setErrors }) => {
      setServerError(false);
      const response = await fetch('/api/kontakt', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        if (window && typeof window.trackGoogleAdsEvent === `function`) {
          window.trackGoogleAdsEvent('conversion', { send_to: 'AW-685688701/064TCPfbm8ABEP2O-8YC' });
        }
        finish();
      } else if (response.status === 409) {
        const jsonData = await response.json();
        const errors = jsonData.errors;
        if (Array.isArray(errors)) {
          const e = {};
          errors.forEach((errorResult) => {
            e[errorResult.field] = errorResult.msg;
          });
          setErrors(e);
        } else {
          showErrorStep();
          setServerError(true);
        }
      } else {
        showErrorStep();
        setServerError(true);
      }
    },
  });

  const PrevStepButton = () => (
    <SecondaryButton name="prev" type="button" label="Zurück" className={styles.stepNavButton} onClick={() => prev()} />
  );

  const reset = useCallback(() => {
    resetForm({});
    resetStepper();
  }, [resetForm, resetStepper]);

  return (
    <>
      <div id={headerId} className={styles.stepperWrapper}>
        <Container className={clsx(styles.container)}>
          <h2>Jetzt kostenlose Projektanfrage stellen!</h2>
          <h4>Unsere Experten stehen Ihnen jederzeit zur Seite</h4>
          <form onSubmit={handleSubmit}>
            <Stepper numSteps={numSteps} activeStep={activeStep}>
              <Step label="Interesse an?">
                <RadioButtonGroup name="interest" options={interests} onChange={handleChange} onClick={next} />
              </Step>
              <Step label="Wie groß ist Ihr Unternehmen?">
                <RadioButtonGroup name="companySize" options={companySizes} onChange={handleChange} onClick={next} />
                <PrevStepButton />
              </Step>
              <Step label="Wann soll Ihr Projekt fertig sein?">
                <RadioButtonGroup name="scheduler" options={schedulers} onChange={handleChange} onClick={next} />
                <PrevStepButton />
              </Step>
              <Step label="Ihre Kontaktdaten">
                <div className={styles.contactForm}>
                  <TextField
                    id="project-inquiry-name"
                    placeholder="Name"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && errors.name}
                    required={true}
                    value={values.name}
                  />
                  <TextField
                    id="project-inquiry-phone"
                    placeholder="E-Mail Adresse"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && errors.email}
                    required={true}
                    value={values.email}
                  />
                  <TextField
                    type="tel"
                    placeholder="Telefonnummer"
                    name="phone"
                    required={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.phone && errors.phone}
                    value={values.phone}
                  />
                </div>
                <div className={styles.formDataAgreement}>
                  <Checkbox
                    id="project-inquiry-id"
                    className={styles.agreement}
                    name="agreement"
                    onChange={handleChange}
                    error={touched.agreement && errors.agreement}
                    checked={values.agreement}
                  >
                    Ich habe die Hinweise zum Datenschutz zur Kenntnis genommen.
                  </Checkbox>
                </div>
                {serverError && (
                  <div className={styles.serverError}>
                    Es ist ein unbekannter Fehler aufgetreten. Ihre Anfrage konnte nicht übermittelt werden. Bitte
                    versuchen Sie es später noch ein mal. Falls es wiederholt auftritt, rufen Sie uns an{' '}
                    <a href="tel:+494088215940">+49 40 882159 40</a> oder senden Sie uns eine E-Mail mit der Anfrage an{' '}
                    <a href="mailto:kontakt@evelan.de">kontakt@evelan.de</a>
                    <button
                      className={styles.serverErrorCloseBtn}
                      name="close-server-error"
                      aria-label="Fehlermeldung Schließen"
                      onClick={() => {
                        setServerError(false);
                      }}
                    >
                      X
                    </button>
                  </div>
                )}
                <div className={styles.formActions}>
                  <div className={styles.ssl}>
                    <img src={IconSSL} alt="SSL badge" />
                    <p>
                      100% Sichere Datenübertragung. <br />
                      Keine Weitergabe der Daten an Dritte.
                    </p>
                  </div>
                  <PrevStepButton disabled={isSubmitting} />
                  <SubmitButton
                    label="Anfrage absenden"
                    className={styles.formActionsSubmit}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  />
                </div>
              </Step>
              <SuccessStep />
              <ErrorStep onBackForm={reset} />
            </Stepper>
          </form>
        </Container>
      </div>
    </>
  );
}
