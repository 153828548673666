import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { InternalLinkButton } from '../button/internal-link-button';

import styles from './stepper.module.scss';

export function SuccessStep() {
  const {
    portrait: {
      childImageSharp: { fixed: portrait },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            slogan
            contact {
              name
              street
              zip
              location
              phone
              meetingLink
            }
            footerMenuLinks {
              name
              link
            }
            socialMediaLinks {
              name
              link
            }
          }
        }
        portrait: file(relativePath: { eq: "portrait_as.jpg" }) {
          childImageSharp {
            fixed(width: 115, height: 115, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    `
  );
  return (
    <div className={styles.successStep}>
      <h2>Vielen Dank für Ihre Anfrage</h2>
      <div className={styles.responseWrapper}>
        <p>
          Damit Sie ein perfekt zugeschnittenes Angebot erhalten werden wir uns in Kürze bei Ihnen melden, um alle
          Details mit Ihnen zu besprechen.
        </p>
        <p>Bis dahin weiterhin viel Erfolg.</p>
      </div>
      <div className={styles.imgContainer}>
        <Img fixed={portrait} width={115} height={115} alt="CEO" className={styles.portrait} />
      </div>
      <p className={styles.name}>
        Herzliche Grüße
        <br />
        <strong>Andreas Straub</strong>
        <br />
        <span>Geschäftsführer, Evelan GmbH</span>
      </p>
      <InternalLinkButton to="/" className={styles.button} color="highlight">
        Zurück zur Startseite
      </InternalLinkButton>
    </div>
  );
}
