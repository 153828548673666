import React from 'react';
import clsx from 'clsx';
import styles from './button.module.scss';

export function SecondaryButton({ label, onClick, className, ...other }) {
  return (
    <button {...other} className={clsx(styles.button, styles.secondaryButton, className)} onClick={onClick}>
      {label}
    </button>
  );
}
