import React, { useEffect } from 'react';
import { useSpring, animated as a } from 'react-spring';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';
import clsx from 'clsx';
import IconCheckMark from '../../images/icons/icon-check-mark-white.svg';
import styles from './stepper.module.scss';

export function StepperNumbers({ activeStep, childrenDefs }) {
  const [ref, { width }] = useMeasure({ polyfill: ResizeObserver });
  const children = [...childrenDefs];
  children.splice(-2, 2);
  const totalSteps = children.length;

  const partWidth = width / totalSteps;
  const [hidderSpring, setHidderSpring] = useSpring(() => ({ width: width || '100%' }));

  useEffect(() => {
    setHidderSpring({
      width: width - partWidth * activeStep - (partWidth / 2 + 25),
    });
  }, [activeStep, partWidth, setHidderSpring, width]);
  return (
    <div ref={ref} className={styles.stepperNumbersWrapper}>
      <div className={clsx(styles.stepperNumbers, styles.active)}>
        <div className={styles.dash} />
        {children.map((_, index) => (
          <div key={index} className={styles.stepNumberContainer}>
            <div className={clsx(styles.stepNumber, activeStep > index && styles.isDone)}>
              <span>{index + 1}</span>
              <img src={IconCheckMark} width={34} height={23} role="presentation" alt="check-mark" />
            </div>
          </div>
        ))}
      </div>
      <a.div
        className={clsx(styles.arrowIndicator, children.length === activeStep && styles.hideArrow)}
        style={hidderSpring}
      />
      <a.div className={styles.inactiveHidderWrapper} style={hidderSpring}>
        <div className={clsx(styles.stepperNumbers, styles.inactive)} style={{ width }}>
          <div className={styles.dash} />
          {children.map((_, index) => (
            <div key={index} className={styles.stepNumberContainer}>
              <div className={styles.stepNumber} />
            </div>
          ))}
        </div>
      </a.div>
    </div>
  );
}
