import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SEO from '../components/seo';
import Container from '../components/container/container';
import LayoutContent from '../components/layout/layout-content';
import TwoColumnSection from '../components/container/two-column-section';
import { InternalLinkButton } from '../components/button/internal-link-button';
import { ProjectInquirySection } from '../components/sections/project-inquiry-section/project-inquiry-section';
import { PageHeading } from '../components/page-heading/page-heading';
import TrailAnimation from '../components/animate/TrailAnimation';
import styles from '../styles/projektanfrage.module.scss';

const ContactPage = () => {
  const {
    site: {
      siteMetadata: {
        contact: { name, street, zip, location, phone, email },
      },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            contact {
              name
              street
              zip
              location
              phone
              meetingLink
              email
            }
          }
        }
      }
    `
  );

  return (
    <LayoutContent>
      <SEO title="Projektanfrage - Evelan GmbH" bodyClass={styles.body} />
      <div className={styles.background} />
      <Container>
        <PageHeading>Anfrage</PageHeading>
      </Container>

      <TwoColumnSection variant="simple" className={styles.container}>
        <div className={styles.contact}>
          <TrailAnimation delayBetweenItems={50} variant="SlideUpHeavy">
            <h2>Klasse, dass Sie mit uns sprechen wollen!</h2>
            <p>
              Nehmen Sie den Kontakt mit uns auf und Ihre Kunden werden von Ihrer neuen Webseite begeistert sein. Und
              wenn Ihre Kunden begeistert sind, geht es Ihnen auch blendend. Nicht wahr?
            </p>
            <p>
              Deshalb füllen Sie am besten gleich das Anfrageformular aus und bekommen Sie innerhalb der nächsten 24
              Stunden eine kostenlose 1-zu-1 Beratung von unseren Experten:
            </p>
            <InternalLinkButton to="/projektanfrage/#inquiry-header" className={styles.ctaButton}>
              Jetzt Anfragen
            </InternalLinkButton>
            <p>
              oder Terminvorschläge an <a href={'mailto:' + email}>{email}</a> senden. Selbstverständlich erreichen Sie
              uns auch telefonisch:{' '}
              <a className={styles.phone} href={'tel:' + phone.replace(/ /g, '')}>
                {phone}
              </a>
            </p>
          </TrailAnimation>
        </div>

        <div className={styles.address}>
          <TrailAnimation delayBetweenItems={50} variant="SlideUpHeavy">
            <h2>Wie Sie uns finden</h2>

            <h6>Office</h6>
            <p>
              {name}
              <br />
              {street}
              <br />
              {`${zip} ${location}`}
            </p>
            <h6>Telefon</h6>
            <p>
              <a className={styles.phone} href={'tel:' + phone.replace(/ /g, '')}>
                {phone}
              </a>
            </p>

            <h6>E-Mail</h6>
            <p>
              <a href={'mailto:' + email}>{email}</a>
            </p>
          </TrailAnimation>
        </div>
      </TwoColumnSection>
      <ProjectInquirySection />
    </LayoutContent>
  );
};

export default ContactPage;
