import React from 'react';
import clsx from 'clsx';
import styles from './container.module.scss';

const TwoColumnSection = ({ className, children, variant = 'normal', ...other }) => {
  return (
    <section
      className={clsx(
        styles.container,
        variant === 'normal' && styles.twoColumnSection,
        variant === 'reverse' && styles.twoColumnSectionReverse,
        variant === 'simple' && styles.twoColumnSectionSimple,
        variant === 'fix' && styles.twoColumnSectionFix,
        className
      )}
      {...other}
    >
      {children}
    </section>
  );
};

export default TwoColumnSection;
