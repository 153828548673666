import React from 'react';
import styles from './forms.module.scss';

export function RadioButtonGroup({ options = [], name, ...other }) {
  return (
    <div className={styles.radioButtonGroup}>
      {options.map(({ label, icon, value }, idx) => (
        <RadioButton key={`rb-${idx}`} label={label} name={name} value={value} icon={icon} {...other} />
      ))}
    </div>
  );
}

export function RadioButton({ label, icon, ...other }) {
  return (
    <label className={styles.radioWrapper}>
      <input {...other} type="radio" />
      <div className={styles.radioImage}>
        {icon && <img src={icon} alt="label" role="presentation" width={80} height={80} />}
        <span>{label}</span>
      </div>
    </label>
  );
}
