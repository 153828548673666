import React, { cloneElement, useState } from 'react';
import { useSpring, useSprings, animated as a, config } from 'react-spring';
import clsx from 'clsx';
import { StepperNumbers } from './stepper-numbers';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';
import styles from './stepper.module.scss';

export function useStepper({ initialActiveStep, numSteps }) {
  const [activeStep, setActiveStep] = useState(initialActiveStep);
  const next = () => {
    if (activeStep < numSteps - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  const prev = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const reset = () => {
    setActiveStep(0);
  };

  const finish = () => {
    setActiveStep(numSteps);
  };

  const showErrorStep = () => {
    setActiveStep(numSteps + 1);
  };

  return { activeStep, next, prev, finish, reset, showErrorStep };
}

export function Stepper({ className, activeStep, children = [], numSteps = 0 }) {
  const childrenDefs = React.Children.toArray(children);

  const [activeItemRef, { height: activeItemHeight }] = useMeasure({ polyfill: ResizeObserver });

  const heightSpring = useSpring({
    height: activeItemHeight,
  });

  const [stepSprings, setSprings] = useSprings(childrenDefs.length, (index) => {
    const isActive = activeStep === index;
    const direction = isActive ? 0 : index > activeStep ? 150 : -150;
    return {
      opacity: isActive ? 1 : 0,
      transform: `translateX(${direction}px)`,
      pointerEvents: isActive ? 'all' : 'none',
    };
  });

  setSprings((index) => {
    const isActive = activeStep === index;
    const direction = isActive ? 0 : index > activeStep ? 100 : -100;
    return {
      opacity: isActive ? 1 : 0,
      transform: `translateX(${direction}px)`,
      pointerEvents: isActive ? 'all' : 'none',
      delay: isActive ? 100 : 0,
      config: {
        ...config.default,
        tension: isActive ? config.default.tension : 300,
        clamp: true,
      },
    };
  });

  return (
    <div className={clsx(styles.stepper, className)}>
      {numSteps > activeStep && <StepperNumbers activeStep={activeStep} childrenDefs={childrenDefs} />}
      <a.div style={heightSpring} className={styles.steps}>
        {childrenDefs.map((step, idx) => {
          const isActiveItem = idx === activeStep;

          return (
            <a.div key={`step-${step.key}`} className={styles.stepAnimationContainer} style={stepSprings[idx]}>
              <div ref={isActiveItem ? activeItemRef : null}>
                {cloneElement(step, {
                  number: idx + 1,
                  active: activeStep === idx,
                  isFirst: idx === 0,
                  isLast: idx === childrenDefs.length - 1,
                  isComplete: idx < activeStep,
                  key: `step-${idx}`,
                })}
              </div>
            </a.div>
          );
        })}
      </a.div>
    </div>
  );
}

export function Step({ number, active, label, isFirst, isLast, isComplete, children }) {
  return (
    <div className={styles.step}>
      <div className={styles.stepLabel}>{label}</div>
      <div className={clsx(styles.stepContent, active && styles.active)}>{children}</div>
    </div>
  );
}
