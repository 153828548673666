import React from 'react';
import CloseIcon from '../../images/icons/close-icon-secondary.svg';
import { SubmitButton } from '../button/submit';

import styles from './stepper.module.scss';

export const ErrorStep = ({ onBackForm }) => {
  return (
    <div className={styles.successStep}>
      <img src={CloseIcon} role="presentation" alt="Close icon" />

      <h2>Es ist ein Fehler aufgetreten</h2>
      <p>
        Ihre Anfrage konnte leider nicht verarbeitet werden. Sie können uns alternativ telefonisch erreichen: +49 40
        88215940
      </p>
      <SubmitButton
        label="Zurück zur Startseite"
        className={styles.button}
        variant-="secondary"
        color="highlight"
        onClick={onBackForm}
      />
    </div>
  );
};
